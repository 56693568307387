import React, { useEffect } from "react";
import AllLaunches from "./container/board";
import CreateBlack from "./container/create.tsx";
import NotFound from "./container/NotFound";
import BuyPage from "./container/BuyPage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
import { WagmiProvider } from 'wagmi'
import { bsc, mainnet, base, polygon, sepolia } from 'wagmi/chains'
import Profile from "./container/Profile.tsx";
import EditProfile from "./container/EditProfile";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import './index.css';
import Faq from "./container/Faq";
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from './config.jsx'

const projectId = '4807d388fe495226b7fc14743af2e1d9'

const queryClient = new QueryClient()

const metadata = {
  name: 'hotkeyswap',
  description: 'hotkeyswap Wagmi',
  url: 'https://hotcurves.fun/',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}


const sepolia_c = {
  ...sepolia,
  rpcUrls: {
    default: {
      http: ["https://1rpc.io/sepolia"],
    },
  }
}

const apechain = {
  id: 33139,
  network: "apechain",
  name: "ApeChain",
  nativeCurrency: {
    name: "ApeCoin",
    symbol: "APE",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://apechain.calderachain.xyz/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "ApeChain Exploerer",
      url: "https://explorer.curtis.apechain.com",
    },
  },
  testnet: false,
};

const SubtensorEVM = {
  id: 964,
  network: "Subtensor EVM",
  name: "Subtensor EVM",
  nativeCurrency: {
    name: "TAO",
    symbol: "TAO",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://lite.chain.opentensor.ai"],
    },
  },
  blockExplorers: {
    default: {
      name: "Taostats Explorer",
      name: "Taostats Explorer",
      url: "https://taostats.io/evm/",
    },
  },
  testnet: false,
};

const chains = [
  // bsc,
  // mainnet,
  // base,
  // polygon,
  // sepolia_c,
  // apechain,
  SubtensorEVM
]

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({
  themeVariables: {
    '--w3m-accent': '#fb03aa',
    '--w3m-border-radius-master': '12px'
  },
  projectId,
  metadata,
  wagmiConfig
})

const App = () => {
  return (
    <WagmiProvider reconnectOnMount config={config}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <QueryParamProvider>
            <div>
              <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{ duration: 5000 }}
              >
                {(t) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <ToastBar onClick={() => alert(1)} toast={t} />
                  </div>
                )}
              </Toaster>
              <Switch>
                <Route exact path="/">
                  <AllLaunches />
                </Route>
                {/* <Route exact path="/MyContributions">
                  <MyContributions />
                </Route> */}
                <Route exact path="/dashboard">
                  <AllLaunches />
                </Route>
                <Route exact path="/createAgent">
                  <CreateBlack />
                </Route>
                <Route exact path="/Buy">
                  <BuyPage />
                </Route>
                <Route exact path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/edit">
                  <EditProfile />
                </Route>
                <Route exact path="/faq">
                  <Faq />
                </Route>
                <Route exact path="/NotFound">
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </QueryParamProvider>
        </Router>
      </QueryClientProvider>
    </WagmiProvider >
  );
};

export default App;
